$(document).ready(function(){

  $('body').on('click', '.catalog_menu_notactive',function(){
    $('.catalog_menu_open').removeClass("catalog_menu_active");
    $('.catalog_menu_open').addClass("catalog_menu_notactive");
    $(this).removeClass("catalog_menu_notactive");
    $(this).addClass("catalog_menu_active");
    $(".catalog_osn").slideUp(200);
    $(this).parents("li").find(".catalog_osn").slideDown(1000);
  });

  $('body').on('click', '.tab_not_active',function(){
    var id = $(this).data('hash');
    $('.card_big_tab').removeClass("tab_active");
    $('.card_big_tab').addClass("tab_not_active");
    $(this).removeClass("tab_not_active");
    $(this).addClass("tab_active");
    $('.card_big_text').hide();
    $("#tab_"+id).show();

    $('.nano').nanoScroller({
      preventPageScrolling: true,
      alwaysVisible: true
    });
  });

  $('body').on('click', ".card_big_button",function(){
    $(".card_big_button").removeClass("active");
    $(this).addClass("active");
    $(".window").removeClass("window_down");
  });

  $('body').on('click', ".window_fon, .cross",function(){
     $(".window_body").fadeOut(600);
     $(".card_big_button").removeClass("active");
     $(".window").addClass("window_down");
  });
  $('body').on('click', "#button_nun",function(){
    $("#nuances").fadeIn(300,function(){
      $('.nano').nanoScroller({
        preventPageScrolling: true,
        alwaysVisible: true
      });
    });
  });
  $('body').on('click', "#button_add",function(){
    $("#additional").fadeIn(300,function(){
      $('.nano').nanoScroller({
        preventPageScrolling: true,
        alwaysVisible: true
      });
    });
  });

  // $(".vertical").slick({
  //   vertical:true,
  //   arrows:true,
  //   dots: false,
  //   infinite: true,
  //   slidesToShow: 8,
  //   slidesToScroll: 1,
  //   prevArrow: `<button type="button" class="card_ul_prev"></button>`,
  //   nextArrow: `<button type="button" class="card_ul_next"></button>`,
  //   responsive: [{
  //
  //       breakpoint: 835,
  //       settings: "unslick" // destroys slick
  //
  //     }]
  // });

  $('.nano').nanoScroller({
    preventPageScrolling: true,
    alwaysVisible: true
  });


  // $('body').on('click', '.open',function(){
  //   $(this).removeClass("open");
  //   $(this).addClass("not_open");
  //   $(this).parents(".table_osn").find(".close").slideDown(1000);
  //
  // });
  // $('body').on('click', '.not_open',function(){
  //   $(this).removeClass("not_open");
  //   $(this).addClass("open");
  //   $(this).parents(".table_osn").find(".close").slideUp(1000);
  // });
  //
  // $('body').on('click', '.open_shop',function(){
  //   $(this).removeClass("open_shop");
  //   $(this).addClass("not_open_shop");
  //   $(this).parents(".table_osn_shop").find(".close_shop").slideDown(200);
  //
  // });
  // $('body').on('click', '.not_open_shop',function(){
  //   $(this).removeClass("not_open_shop");
  //   $(this).addClass("open_shop");
  //   $(this).parents(".table_osn_shop").find(".close_shop").slideUp(200);
  // });

});
